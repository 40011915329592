import React from "react";
import { renderToString } from "react-dom/server";
import { Link } from "gatsby";
import styled from "styled-components";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import SubscribeForm from "../../components/subscribeForm";
import { Button, HeaderPlaceholder } from "../../styles/ui.styles";
import { useForm, Controller, SubmitHandler } from "react-hook-form";

import Select from "react-select";

// * file upload TODO
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginImageCrop from "filepond-plugin-image-crop"; // Crops image
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageTransform
);

const Ticket: React.FC = () => {
  const {
    register,
    watch,
    control,
    handleSubmit,
    // formState: { errors },
  } = useForm({
    defaultValues: { topic: { value: "tutorials", label: "Tutorials" } },
  });
  const onSubmit: SubmitHandler<FormData> = (data) =>
    alert(JSON.stringify(data));

  const customDropAreaLabel = () =>
    renderToString(
      <div
        style={{
          width: "100%",
          height: "100%",
          margin: "32px auto",
        }}
      >
        <p>Drag and drop here to upload</p>
        <FakeBlackButton className="filepond--label-action">
          OR SELECT FILES
        </FakeBlackButton>
      </div>
    );

  // * only allow submitting when privacy policy is accepted
  const canSubmit = watch("acceptPrivacy");
  const topics = [
    { value: "tutorials", label: "Tutorials" },
    { value: "manuals", label: "Manuals" },
  ];
  return (
    <>
      <SEO title={"Submit a request"} description={"Submit a request"} />
      <Layout>
        <HeaderPlaceholder />
        <Wrapper>
          <h1>Submit a request</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FlexSection>
              <div>
                <label>Select a topic *</label>
                <SelectWrapper>
                  <Controller
                    name="topic"
                    control={control}
                    render={({ field }) => (
                      <Select
                        isClearable={false}
                        classNamePrefix={"type-select"}
                        options={topics}
                        // defaultValue={topics[0]}
                        isSearchable={false}
                        // value={topics[0]}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </SelectWrapper>
              </div>
              <div>
                <label>Subject</label>
                <input
                  type="text"
                  placeholder="subject"
                  {...register("subject", {})}
                />
              </div>
            </FlexSection>
            <label>Message *</label>
            <textarea {...register("message", { required: true })} />
            <FlexSection>
              <div>
                <label>Your Name *</label>
                <input
                  type="text"
                  placeholder="Joe Smith"
                  {...register("name", { required: true })}
                />
              </div>
              <div>
                <label>Email Adress *</label>
                <input
                  type="email"
                  placeholder="joe.smidth@gmail.com"
                  {...register("email", { required: true })}
                />
              </div>
            </FlexSection>
            <label>Relevant URL</label>
            <input
              type="url"
              placeholder="https://example.hu"
              {...register("relevantURL", {})}
            />
            <FileUpload>
              <Controller
                name="files"
                control={control}
                render={({ field }) => (
                  <FilePond
                    files={field.value}
                    allowMultiple={true}
                    allowImageCrop={true}
                    allowImageTransform={true}
                    // imageCropAspectRatio={"1:1"}
                    onupdatefiles={field.onChange}
                    maxFiles={3}
                    // server="/api"
                    labelIdle={customDropAreaLabel()}
                  />
                )}
              />
            </FileUpload>
            <AcceptTerms>
              <Checkbox
                className="custom"
                id="checkbox"
                type="checkbox"
                placeholder="acceptPrivacy"
                {...register("acceptPrivacy", { required: true })}
              />
              <label htmlFor="checkbox"></label>
              <p>
                Read and agree with{" "}
                <Link to={"/terms-and-contitions"}>Terms and conditions</Link>
              </p>
            </AcceptTerms>
            <Button
              aria-label={"Submit"}
              type="submit"
              primary={true}
              disabled={!canSubmit}
            >
              SUBMIT REQUEST
            </Button>
          </form>
        </Wrapper>

        <SubscribeForm />
      </Layout>
    </>
  );
};

export default Ticket;

// 🚨 do not use an actual button as it will submit the form
const FakeBlackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  min-width: 18px;
  height: 44px;
  padding: 0 32px;
  font-size: 16px;
  text-decoration: none;
  color: #000;
  background: #fff;
  border: 2px solid #000;
  cursor: pointer;
  &:hover {
    color: rgba(0, 0, 0, 0.7);
    border-color: rgba(0, 0, 0, 0.7);
  }
  transition: 0.2s;
  margin: 0 auto;
  color: #000;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  padding-bottom: 100px;

  p {
    color: var(--color-dark-grey);
  }

  /* form {
    display: flex;
    flex-direction: column;
  } */
  select,
  input,
  textarea {
    width: 100%;
    height: 200px;
    border: 1px solid var(--color-orange-hovered);
    border-radius: 99px;
    padding-left: 2rem;

    &:focus {
      outline: none;
    }
    &:focus,
    &:hover {
      border-color: var(--color-orange);
    }
  }
  select {
    height: 44px;
    padding-right: 2rem;
    appearance: none; // remove default chevron
  }

  input {
    height: 44px;
    margin-bottom: 20px;
  }
  textarea {
    border-radius: 20px;
    resize: none;
    height: 200px;
    margin-bottom: 70px;
  }

  @media (max-width: 480px) {
    button {
      width: 100%;
    }
  }
`;

const FlexSection = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const AcceptTerms = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  p {
    flex: 1;
  }
`;

const FileUpload = styled.div`
  border: 1px solid var(--color-orange-hovered);
  background: #fff;
  border-radius: 20px;
  overflow: hidden;

  .filepond--panel-root {
    background-color: #fff;
  }

  .filepond--root {
    font-size: 20px;
    background: #fff;
    border-radius: 20px;
  }

  .filepond--drop-label {
    height: auto !important;
    background: #fff;
    border-radius: 20px;
  }
  .filepond--label-action {
    text-decoration: none;
  }
  .filepond--credits {
    margin-right: 16px;
  }
`;

const Checkbox = styled.input`
  &.custom {
    width: 20px;
    height: 20px;
    margin: 0;
    margin-right: 20px;
    padding: 0;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid var(--color-orange);
    appearance: none;
    outline: none;
    cursor: pointer;
    &:checked {
      background-color: var(--color-orange-hovered);
    }
  }
`;

const SelectWrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  .type-select__control {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    width: 100%;
    border: 1px solid var(--color-orange-hovered);
    border-radius: 99px;
    font-size: 1.6rem;
    @media (min-width: 480px) {
      font-size: calc(1.6rem + ((1vw - 0.48rem) * 0.5556));
      min-height: 0vw;
    }
    @media (min-width: 1200px) {
      font-size: 2rem;
    }
    box-shadow: none;

    transition: 0.2s;
    &:hover {
      box-shadow: none;
      border: 1px solid var(--color-orange);
    }
  }

  .type-select__value-container {
    overflow: visible;
    padding-top: 0;
    height: 44px;
    display: block;
    line-height: 42px;
    padding-left: 16px;
  }

  .type-select__control--is-focused {
    box-shadow: none;
    border: 1px solid var(--color-orange);
  }

  .type-select__menu-list {
    padding: 0;
    background: #fff;
    border: 1px solid var(--color-orange);
    border-radius: 22px;
  }

  .type-select__menu {
    width: 100%;
    height: 44px;
    font-size: 1.6rem;
    @media (min-width: 480px) {
      font-size: calc(1.6rem + ((1vw - 0.48rem) * 0.5556));
      min-height: 0vw;
    }
    @media (min-width: 1200px) {
      font-size: 2rem;
    }

    border: unset;
    box-shadow: none;
  }

  .type-select__option {
    padding-left: 16px;
    background-color: #fff;
    cursor: pointer;
    &:active {
      background-color: #fff;
    }
  }
  .type-select__option--is-focused {
    background-color: rgba(248, 51, 28, 0.1);
  }
  .type-select__option--is-selected {
    background-color: var(--color-orange);
  }
`;
